@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900");

.App {
  text-align: center;
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  min-width: 800px;
  background: #1C1B1C;
}

.Navbar {
  height: 60px;
  color: #e6e3dc;
  background: #1C1B1C;
}

.NavbarTitle {
  position: absolute;
  font-size: 22px;
  font-weight: 400;
  padding-top: 19px;
  padding-left: 7px;
}

.NavbarSubTitle {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  padding-top: 24px;
  padding-left: 125px;
}

.NavbarLinks {
  text-align: right;
  color: #e6e3dc;
  padding-top: 23px;
  padding-right: 9px;
}

.NavbarLink {
  color: #e6e3dc;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  padding-left: 22px;
  text-decoration: none;
}

.NavbarLink:hover {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.Gallery {
  text-align: center;
  background: #e6e3dc;
  min-height: 900px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
}

.PageNavigation {
  text-align: center;
}

.PageButton {
  font-size: 16px;
  font-weight: 500px;
  margin: 10px;
  display: inline-block;
}

.PageButtonDisabled {
  font-size: 16px;
  font-weight: 500px;
  margin: 10px;
  color: rgba(0, 0, 0, 0.3);
  display: inline-block;
}

.Artworks {
  padding-top: 20px;
  padding-bottom: 50px;
}

.Artwork {
  width: 300px;
  height: 300px;
  padding: 20px;
  display: inline-block;
}

.ArtworkImage {
  border: none
}

.ArtworkNumber {
  font-family: "Inter";
  font-size: 14px;
  text-align: left;
  position: absolute;
  padding-top: 5px;
  padding-left: 5px;
}

.ArtworkNumber:hover {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.ArtworkVariantToggle{
  font-family: "Inter";
  font-style: italic;
  font-size: 20px;
  text-align: right;
  margin-top: -5px;
  margin-right: 5px;
}

.ArtworkVariantToggle:hover {
  font-weight: 700;
  cursor: pointer;
}

.Legal {
  background: #e6e3dc;
  color: #1C1B1C;
  font-size: 16px;
  font-weight: 500;
}

.LegalContainer {
  padding-top: 20px;
  margin-left: 350px;
  margin-right: 350px;
  margin-bottom: 50px;
  min-width: 400px;
}

.LegalTitle {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}

.LegalSubTitle {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}

.LegalSection {
  margin-top: 25px;
  text-align: left;
  font-weight: 800;
}

.LegalHeader {
  text-align: left;
  font-weight: 800;
}

.LegalText {
  text-align: left;
  font-weight: 400;
}

.LegalTextBreak {
  margin-top: 25px;
  text-align: left;
  font-weight: 400;
}

.LegalLink:hover {
  color: #1C1B1C;
  font-weight: 800;
}
